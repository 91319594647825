import { SyncLoader } from 'react-spinners';

export default function Loading() {
    return (
        <div>
            <SyncLoader
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Set the height to full viewport height if needed
                }}
                color="#5d5ff0"
                cssOverride={{}}
                loading
                margin={5}
                size={32}
            />
        </div>
    );
}